<template>
  <div class="bg-gray">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12">
        <div
          class="
            space-y-5
            sm:space-y-4
            md:max-w-xl
            lg:max-w-3xl
            xl:max-w-none
            border-b
            pb-4
          "
        >
          <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
            Gallery
          </h2>
          <p class="text-xl text-gray-500">
            Use our gallery to help you decide which shade option you would like
            on your vehicle.
            <span class="text-red-400">This may take a moment to load.</span>
          </p>
        </div>
        <div v-if="error">
          Could not fetch gallery images... please try again later
        </div>
        <div v-if="documents">
          <form @submit.prevent>
            <input class="hidden" type="text" v-model="hiddenChoice" disabled />

            <div class="grid grid-cols-3 md:m-10">
              <button
                @click="filterImages(type.type)"
                class="m-2 p-2 text-sm bg-gray-800 text-white rounded px-2"
                v-for="type in tabs"
                :key="type.type"
              >
                {{ type.type }}
              </button>
            </div>
          </form>

          <div
            class="bg-gray-900 lg:m-10 rounded-xl"
            v-for="install in documents"
            :key="install.id"
          >
            <div v-if="hiddenChoice === install.vehicleType" class="p-2 my-5">
              <div class="aspect-h-2">
                <img
                  loading="lazy"
                  class="object-contain shadow-lg rounded-lg mx-auto h-96 w-full"
                  :src="install.imageURL"
                  alt=""
                />
              </div>
              <div class="space-y-2 text-gray-100 mt-5">
                <div
                  class="p-2 text-2xl leading-6 font-medium space-y-1 border-b"
                >
                  <div>
                    <h3>
                      {{ install.vehicleName }}
                      <span
                        class="
                          text-xs text-gray-800
                          rounded-xl
                          py-1
                          px-2
                        "
                        :class="`
                          ${bgbrand300}`"
                        >{{ install.vehicleType }}</span
                      >
                    </h3>
                  </div>
                </div>
                <div>
                  <p class="md:text-lg p-2">
                    Install type: {{ install.installType }}
                  </p>
                  <ul
                    class="flex p-2 rounded text-gray-900 space-x-5 bg-gray-300"
                  >
                    <li class="md:text-lg font-semibold">
                      Rear Tint: {{ install.shadeOptionRear }}
                    </li>
                    <li class="md:text-lg font-semibold">
                      Front Tint: {{ install.shadeOptionFront }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";
import { ref } from "vue";
import configSetup from "@/setup.js";

export default {
  created() {
    const { company } = configSetup();
    document.title = `${company} | Gallery`;
  },

  setup() {
    const { bgbrand300,dbGallery } = configSetup();
    const { user } = getUser();
    const { error, documents } = getCollection('companyProfile', 'gallery');

    let hiddenChoice = ref("");

    const tabs = [
      { type: "5DR Hatchback" },
      { type: "Compact SUV" },
      { type: "Station Wagon" },
      { type: "Coupe" },
      { type: "SUV" },
      { type: "Sedan" },
      { type: "3DR Hatchback" },
      {type: '2DR Truck'},
      {type: '4DR Truck'},
    ];

    const filterImages = (tabSelection) => {
      hiddenChoice.value = tabSelection;
    };

    return {
      documents,
      error,
      user,
      tabs,
      hiddenChoice,
      filterImages,
      bgbrand300,
    };
  },
};
</script>